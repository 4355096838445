<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd"
            >添加每日一句</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="dailyOneList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
       
        <el-table-column label="日语" prop="original" align="center"></el-table-column>
        <el-table-column label="中文" prop="chinese" align="center"></el-table-column>
        <el-table-column label="缩略图" align="center">
          <template slot-scope="scope">
            <div class="thumb_box">
                <img :src="scope.row.thumb_url" alt="">
            </div>
           
          </template>
        </el-table-column>
        <el-table-column label="展示时间" align="center">
          <template slot-scope="scope">
            {{
                $moment(scope.row.play_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
          </template>
        </el-table-column>
        
        <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
          </template>
        </el-table-column>
       
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editGoods(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeTask(scope.row.id)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 添加每日一句 -->
      <el-dialog :title="addtitle" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="中文" label-width="100px">
            <el-input
              type="text"
              v-model="addForm.chinese"
              placeholder="请输入中文"
            />
        </el-form-item>
        <el-form-item el-form-item label="日语" label-width="100px">
            <el-input
              type="text"
              v-model="addForm.original"
              placeholder="请输入日语"
            />
        </el-form-item>

        <el-form-item el-form-item label="展示日期" label-width="100px">
            <el-date-picker
              v-model="addForm.play_time"
              type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
        </el-form-item>

        <el-form-item  el-form-item label="是否节假日" label-width="100px">
          <el-select
            v-model="addForm.is_festival"
            placeholder="请选择是否节假日"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in festivalArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item el-form-item label="缩略图" label-width="100px">
            <el-row>
              <el-upload
                class="avatar-uploader"
                action="123"
                :show-file-list="false"
                accept="image/*"
                :before-upload="beforeThumbUpload"
                style="width: 259px;height:390px"
              >
                  <img
                    v-if="thumbSrc"
                    style="width: 100%;height:100%"
                    :src="thumbSrc"
                    class="avatar"
                  />
                  <i
                  v-else
                  class="el-icon-plus avatar-uploader-icon"
                  ></i>
              </el-upload>
            </el-row>
            <el-row>
              <div class="tips">上传角色缩略图尺寸为1035 X 1560且不大于10MB</div>
            </el-row>
        </el-form-item>

        <el-form-item el-form-item label="音频" label-width="100px">
          <div v-if="addForm.audio_url">
                <audio
                  :src="addForm.audio_url"
                  style="cursor: pointer;outline: none;"
                  controls="controls" 
                  ref="audioPlay"
                ></audio>
          </div>
          <el-button type="primary" @click="selectAudio"
            >{{isAdd=='add'?'上传音频':'修改音频'}}</el-button
          ><span class="file-name">{{ fileAudioName }}</span>
          <el-progress :percentage="progress" v-if="progress"></el-progress>
          <input
            type="file"
            name="audio"
            id=""
            hidden
            ref="audio"
            @change="selectAudioFiles"
            accept="audio/*"
          />
        </el-form-item>

       
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd=='add'" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editReadNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var COS = require("cos-js-sdk-v5");

import {
  DailyOneList,
  DailyOneDel,
  DailyOneAdd,
  DailyOneEdit,
  getDailyOneCosToken,
} from "@/api/Japanese_app/daily_oneList.js";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      dailyOneList: [],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        chinese: "",
        original: "",
        thumb: "",
        audio: "",
        play_time: "",
        is_festival: "",
      },
      addtitle: "",
      isAdd: "add",
      fileAudioName: "",
      progress: 0,
      thumbSrc: "",
      festivalArr: [
        { key: 0, value: "否" },
        { key: 1, value: "是" },
      ],
    };
  },

  created() {
    this.getDailyOneList();
  },
  methods: {
    //   日语APP每日一句列表
    getDailyOneList() {
      DailyOneList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.dailyOneList = res.data.data;
      });
    },

    // 删除每日一句
    async removeTask(id) {
      const result = await this.$confirm("是否要删除该每日一句?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      DailyOneDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除每日一句成功");
        this.getDailyOneList();
      });
    },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "添加每日一句";
      this.lineData = [];
      this.addDialog = true;
      this.isCascader = true;
    },
    // 点击选择音频文件按钮
    selectAudio() {
      this.$refs.audio.click();
    },

    // 选中音频
    selectAudioFiles(e) {
      let file = this.$refs.audio.files[0];
      this.fileAudioName = file.name;
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      getDailyOneCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.progress = progressData.percent * 100;
            },
          },
          function (err, data) {
            if (data) {
              that.addForm.audio = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },

    // 上传缩略图
    beforeThumbUpload(file) {
      let that = this;
      const is1M = file.size / 1024 / 1024 < 10; // 限制小于1M
      const isSize = new Promise(function (resolve, reject) {
        let width = 1035; // 限制图片尺寸为654X270
        let height = 1560;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          let valid = img.width === width && img.height === height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          getDailyOneCosToken({ suffix: file.name }).then((res) => {
            var cos = new COS({
              getAuthorization: function (options, callback) {
                callback({
                  TmpSecretId: res.data.credentials.tmpSecretId,
                  TmpSecretKey: res.data.credentials.tmpSecretKey,
                  XCosSecurityToken: res.data.credentials.sessionToken,
                  StartTime: res.data.startTime,
                  ExpiredTime: res.data.expiredTime,
                  expiration: res.data.expiration,
                  requestId: res.data.requestId,
                });
              },
            });
            cos.putObject(
              {
                Bucket: res.data.Bucket,
                Region: res.data.Region,
                Key: res.data.key,
                // ContentType:'multipart/form-data',
                Body: file, // 上传文件对象
                onProgress: function (progressData) {},
              },
              function (err, data) {
                if (data) {
                  that.addForm.thumb = res.data.key;
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = (e) => {
                    that.thumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                    that.$forceUpdate();
                  };
                }
              }
            );
          });
          return file;
        },
        () => {
          that.$message.error(
            "上传缩略图尺寸限制为1035 x 1560，大小不可超过10MB"
          );
          // return Promise.reject();
        }
      );
    },

    // 添加每日一句
    addNow() {
      if (this.addForm.chinese == "") {
        this.$message.error("请输入中文翻译");
        return;
      } else if (this.addForm.original == "") {
        this.$message.error("请输入日语原文");
        return;
      } else if (
        this.addForm.play_time == "" ||
        this.addForm.play_time == null
      ) {
        this.$message.error("请选择展示日期");
        return;
      } else if (this.addForm.is_festival === "") {
        this.$message.error("请选择是否节假日");
        return;
      } else if (this.addForm.thumb == "") {
        this.$message.error("请选择封面图");
        return;
      }
      this.addForm.play_time =
        new Date(this.addForm.play_time).getTime() / 1000;
      DailyOneAdd(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加每日一句成功");
        this.addDialog = false;
        this.getDailyOneList();
      });
    },

    // 点击编辑图标
    editGoods(item) {
      this.isAdd = "edit";
      this.addtitle = "编辑每日一句";
      this.addDialog = true;
      this.addForm = JSON.parse(JSON.stringify(item));
      this.addForm.play_time = this.addForm.play_time * 1000;
      this.thumbSrc = this.addForm.thumb_url;
    },

    // 点击立即修改
    editReadNow() {
      if (this.addForm.chinese == "") {
        this.$message.error("请输入中文翻译");
        return;
      } else if (this.addForm.original == "") {
        this.$message.error("请输入日语原文");
        return;
      } else if (
        this.addForm.play_time == "" ||
        this.addForm.play_time == null
      ) {
        this.$message.error("请选择展示日期");
        return;
      } else if (this.addForm.is_festival === "") {
        this.$message.error("请选择是否节假日");
        return;
      } else if (this.addForm.thumb == "") {
        this.$message.error("请选择封面图");
        return;
      }
      this.addForm.play_time =
        new Date(this.addForm.play_time).getTime() / 1000;
      DailyOneEdit(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改每日一句成功");
        this.addDialog = false;
        this.getDailyOneList();
      });
    },

    // 弹窗关闭事件
    closed() {
      this.addForm = {
        chinese: "",
        original: "",
        thumb: "",
        audio: "",
        play_time: "",
      };
      this.thumbSrc = "";
      this.fileAudioName = "";
      this.progress = 0;

      if (this.$refs.audioPlay !== undefined) {
        this.$refs.audioPlay.pause();
      }
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getDailyOneList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getDailyOneList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getDailyOneList();
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;
  img {
    width: 120px;
    height: 200px;
  }
}

.tips {
  color: red;
}
::v-deep .el-upload--text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
